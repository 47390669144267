<template>
  <div style="display: contents">
    <v-btn color="color3" text x-small @click.stop="open">Game Reminders</v-btn>
    <v-navigation-drawer
      right
      v-model="isOpen"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="editing"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color2 color2Text--text" tile dense>
          <v-toolbar-title>Game Reminders</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab
            small
            @click.stop="cancel(true)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-btn
                :color="activeBtn.color"
                :disabled="activeBtn.disabled"
                :loading="activeBtn.loading"
                @click.stop="activeBtn.onClick"
                block
                class="py-9"
              >
                {{ activeBtn.text }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="activeBtn.cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              {{activeBtn.cancelText || 'Cancel'}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-card-text :class="{ 'px-0': window === 1 }">
        <v-container class="pt-10 px-0">
          <v-window v-model="window">
            <v-window-item :key="0">
              <v-row dense>
                <v-col cols="12" class="text-end" v-if="false && history.length > 0">
                  <v-btn
                    color="color3"
                    text
                    @click="window=1"
                    small
                  >
                    View Logs
                    <v-icon class="ml-2">fas fa-caret-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="caption" v-if="!isNew">
                  Id: {{mySettings.id}} | Created: {{mySettings.dtCreated ? mySettings.dtCreated.format('LLLL') : 'NA'}} By: {{mySettings.createdBy}}
                </v-col>
                <v-col cols="12" class="title text-center">
                  Game Reminders are: <span :class="settings && settings.active ? 'success--text' : 'error--text'">{{ settings && settings.active ? 'On' : 'Off' }}</span>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Enable"
                    v-model="mySettings.active"
                    color="success"
                    :disabled="!editing"
                  ></v-switch>
                </v-col>
                <v-col cols="6">
                  <!-- DaysBefore -->
                  <v-select
                    :items="[0,1,2,3,4,5,6,7]"
                    v-model="mySettings.daysBefore"
                    :disabled="!editing"
                    hint="How many days before the match should we send the reminder?"
                    persistent-hint
                    label="Days Before"
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="times"
                    v-model="mySettings.runTime"
                    item-text="text"
                    item-value="value"
                    label="Send Time"
                    :disabled="!editing"
                    persistent-hint
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense class="mt-3" v-if="settings.jobs && settings.jobs.length">
                <v-col cols="12" class="title">
                  <div class="d-flex justify-space-between">
                    Schedule
                    <v-btn
                      color="color3"
                      small text icon
                      @click.stop="updateSchedule"
                      :disabled="editing"
                    >
                      <v-icon>fas fa-sync-alt</v-icon>
                    </v-btn>
                  </div>
                  <v-divider></v-divider>
                </v-col>
                <v-expand-transition
                  v-for="(job, i) in settings.jobs"
                  :key="job.id"
                >
                  <v-col cols="12" v-if="job.status !== 'Canceled' || canceledJobs">
                    <v-divider v-if="i > 0 && (settings.jobs[i-1].status !== 'Canceled' || canceledJobs)"></v-divider>
                    <game-reminder-job :job="job" :disabled="editing" :league="tournament"></game-reminder-job>
                  </v-col>
                </v-expand-transition>
                <v-col cols="12" class="text-center">
                  <v-btn
                    v-if="settings.jobs.findIndex(f => f.status === 'Canceled') > -1"
                    color="color3"
                    @click.stop="canceledJobs = !canceledJobs"
                    small text
                  >
                    {{ canceledJobs ? 'hide' : 'show' }} canceled jobs
                  </v-btn>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-container>
        <v-overlay color="white" v-if="loading">
          <div class="text-center">
            <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
          </div>
          <div class="color3--text text-center text-h4">Loading</div>
        </v-overlay>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { timeOptions } from '@/classes/HelperFunctions'
import { localDate, localDt } from '@/Filters'
import moment from 'moment'
const GameReminderJob = () => import('./GameReminderJob')
// import { firstBy } from 'thenby'

export default {
  props: ['promo', 'existing', 'username'],
  data () {
    return {
      isOpen: false,
      editing: false,
      iSettings: {},
      settings: {
        id: 0,
        tournamentId: 0,
        active: false,
        daysBefore: 2,
        runTime: '9:30'
      },
      saving: false,
      error: null,
      window: 0,
      loading: false,
      history: [],
      canceledJobs: false
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament']),
    isNew () {
      return this.settings && !this.settings.id
    },
    mySettings () {
      return this.editing ? this.iSettings : this.settings
    },
    activeBtn () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        text: 'Edit',
        cancel: () => { this.cancel(true) }
      } : {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.saveSettings,
        text: 'save',
        cancel: this.cancel,
        disabled: !this.dirty
      }
    },
    dirty () {
      return this.isNew ? this.mySettings.active : JSON.stringify(this.settings) !== JSON.stringify(this.iSettings)
    },
    valid () {
      return this.mySettings.daysBefore && this.discountValue && !this.taken && !this.maxErrors.length
    },
    dto () {
      return this.myPromo.dto
    },
    times () {
      return timeOptions()
    }
  },
  methods: {
    displayDate (d) {
      return localDate(d)
    },
    displayDateTime (d) {
      return localDt(d)
    },
    onSaveClick () {
      this.$v.$touch()
      if (!this.valid) return
      this.saving = true
      this.$VBL.cart.promos.post(this.myPromo)
        .then(r => this.$emit('save-complete', this.myPromo))
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.saving = false })
    },
    edit () {
      this.iSettings = JSON.parse(JSON.stringify(this.settings))
      this.editing = true
    },
    cancel (close) {
      this.editing = false
      this.advanced = false
      this.iSettings = {}
      if (this.isNew || close === true) this.isOpen = false
      this.history = []
      this.window = 0
    },
    open () {
      this.isOpen = true
    },
    getSettings () {
      this.loading = true
      this.$VBL.tournament.league.gameReminders.get(this.tournament.id)
        .then(r => {
          if (r.data) {
            this.loadSettings(r.data)
          } else {
            this.edit()
          }
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    saveSettings () {
      this.loading = true
      this.$VBL.tournament.league.gameReminders.post(this.tournament.id, this.iSettings)
        .then(r => {
          this.loadSettings(r.data)
          this.cancel()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    updateSchedule () {
      this.loading = true
      this.$VBL.tournament.league.gameReminders.post(this.tournament.id, {})
        .then(r => {
          this.loadSettings(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    loadSettings (dto) {
      this.settings = dto
      this.settings.jobs.forEach(j => {
        j.tournamentDayDate = moment(j.tournamentDayDate)
        j.dtStart = moment(j.dtStart.replace('Z', ''))
      })
      this.settings.jobs.sort((a, b) => a.tournamentDayDate.unix() - b.tournamentDayDate.unix())
    },
    getUsage () {
      this.loading = true
      this.$VBL.cart.promos.getUsage(this.username, this.promo.id)
        .then(r => { this.usage = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    isOpen: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.getSettings()
      }
    },
    editing: function (val) {
      this.$emit('editing-change', val)
    },
    window: function (val) {
      if (val === 1) this.getUsage()
    }
  },
  components: {
    GameReminderJob
  }
}
</script>
